/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate } from 'gatsby'
import {
  Grid,
  Box,
  Button,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { get } from 'lodash'
import * as Accessa from 'accessa'
import { useTranslation } from 'react-i18next'
import {
  Breadcrumbs,
  RichText,
  withGlobals,
} from '../components'
import {
  getFirstDefinedImage,
  toFeaturePropsShape,
} from '../components/ArchiveLayoutBlogs'

const useStyles = makeStyles(() => ({
  blogHeader: {
    '& > header': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: '350px',
    },
  },
}))

const fnToBoo = (f) => typeof f === 'function'

const getBlogPath = (locale) => (blog) => {
  const path = get(blog, 'relativePath', null)
  // eslint-disable-next-line no-nested-ternary
  return path
    ? get(blog, 'node_locale', null) === locale
      ? () => navigate(path)
      : null
    : null
}

const Blog = ({
  contentfulBlog: {
    id,
    title,
    image,
    categories,
    // eslint-disable-next-line camelcase
    node_locale,
    article,
  },
  pageContext: { previous, next },
}) => {
  const { t } = useTranslation('labels')
  const generateOnClick = getBlogPath(node_locale)
  const previousPath = generateOnClick(previous)
  const nextPath = generateOnClick(next)
  const cls = useStyles()

  return (
    <Box component="main" pt={5} className={cls.blogHeader}>
      <Accessa.Layouts.BlogTemplate
        HeaderProps={{
          title,
          fluid:
            get(image, 'fluid') ||
            getFirstDefinedImage(categories),
        }}
        FeatureProps={{
          lists: toFeaturePropsShape(
            categories
              .flatMap((item) => item.blog)
              .filter((x) => x.id !== id)
          ).slice(0, 3),
        }}
        FeatureWrapper={({ children }) => (
          <Box component="aside" my={4}>
            <Typography variant="h3">
              {t('related')}
            </Typography>
            {children}
          </Box>
        )}
      >
        <Box>
          <Breadcrumbs title={title} />
          <Box mb={4}>
            <Divider />
          </Box>
        </Box>
        <Box component="article">
          <RichText
            json={get(article, 'json')}
            // eslint-disable-next-line
            locale={node_locale}
          />
        </Box>
        <Box mb={2} mt={4}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={!fnToBoo(previousPath)}
                onClick={previousPath}
              >
                {t('previous')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={!fnToBoo(nextPath)}
                onClick={nextPath}
                variant="contained"
                color="primary"
                fullWidth
              >
                {t('next')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Accessa.Layouts.BlogTemplate>
    </Box>
  )
}

export const query = graphql`
  query($id: String!) {
    contentfulBlog(id: { eq: $id }) {
      id
      title
      publishedOn
      relativePath
      node_locale
      seo {
        title
        description {
          description
        }
      }
      article {
        json
      }
      categories {
        blog {
          id
          title
          relativePath
          image {
            fluid {
              srcSet
            }
          }
          node_locale
          categories {
            featuredImage {
              fluid {
                srcSet
              }
            }
          }
        }
        featuredImage {
          fluid {
            srcSet
          }
        }
      }
      image {
        fluid {
          srcSet
        }
      }
    }
  }
`

Blog.propTypes = {
  contentfulBlog: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.object,
    categories: PropTypes.object.isRequired,
    node_locale: PropTypes.string.isRequired,
    article: PropTypes.object,
  }).isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default withGlobals(Blog)
